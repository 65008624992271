import React from "react";
import { Switch, Route} from "react-router-dom";

import PageLogin from "../pages/Login";
import PageForgotPassword from "../pages/ForgotPassword";

const AuthRoutes: React.FC = () => { 
    return(
        <Switch>
            <Route path="/" exact component={PageLogin} />
            <Route path="/forgot-password/:redefined?" exact component={PageForgotPassword} />
        </Switch>
    )
}

export default AuthRoutes;