import React from 'react';

import { Container } from './style';

interface iButtonBar{
  hideTopLine?: boolean;
  topMargin?: string;
  topPadding?: string;
}

const ButtonBar: React.FC<iButtonBar> = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
        { children }
    </Container>
  );
}

export default ButtonBar;