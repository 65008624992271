import React from "react";

import { Container  } from "./style";

interface iInputLabel {
    type: string;
    required?: boolean;
    maxLength?: number;
    placeholder?: string;
    borderColor?: string;
    focussedBorderColor?: string;
    value?: string;
    name?: string;
    legend?: string;
    subtitle?: string;
    onChange?(value: string): void;
    onInvalide?(): void;
    disabled?: boolean;
    labelBold?: boolean;
    grayMode?: boolean;
    reference?: React.LegacyRef<HTMLInputElement>;
}

const InputLabel: React.FC<iInputLabel> = ( props ) => {
    return (
        <Container labelBold={props.labelBold} grayMode={props.grayMode}>
            <span>{ props.children }</span>
            { props.subtitle&& <span className="subtitle">{props.subtitle}</span> }
            <input 
                ref={props.reference}
                onInvalid={props.onInvalide}
                type={ props.type } 
                required={ props.required } 
                maxLength={ props.maxLength } 
                placeholder={ props.placeholder }
                onChange={ (e) => props.onChange && props.onChange(e.target.value)}
                value={ props.value }
                name={props.name}
                disabled={props.disabled? props.disabled : false}
                />
            <small>{props.legend}</small>
        </Container>
    )
}

export default InputLabel;