import avatarImg from '../assets/avatar.png';
import { apiMember } from '../types/api'; 
import { apiBaseUrl } from '../configs';

function resolveAvatarImage(member: apiMember | null)
{
    if(member === null)
        return avatarImg;
    else
        return member.profile? 
            member.profile.avatar!=null? apiBaseUrl+member.profile.avatar : 
             avatarImg : 
                avatarImg;
}

export default resolveAvatarImage;