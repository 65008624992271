import React from "react";

import { Container } from "./style";

interface iButton {
    fontSize?: string;
    fontColor?: string;
    fontWeight?: string;
    backgroundColor?: string;
    borderColor?: string;
    borderWeight?: number;
    focussedBorderColor?: string;
    mouseHoverBackgroundColor?: string;
    padding?: string;
    onClick?(): void;
    width?: string;
    margin?: string;
    disabled?: boolean;
    textAlign?: "flex-start" | "center" | "flex-end";
}

const Button: React.FC<iButton> = ({ children, ...rest}) => {
    return (
        <Container {... rest}>
            { children }
        </Container>
    )
}

export default Button;