import styled from "styled-components";

interface iContainer {
    background?: string;
}

export const Container = styled.div<iContainer>`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${props => props.theme.colors.surface_5};
    background-image: ${props => props.background? `url(${props.background})` : ``};
    background-position: center;
    background-size: cover;
`;

export const FormContainer = styled.div`
    width: 480px;
    display: flex;
    flex-direction: column;

    @media(max-width: 500px)
    {
        width: 100%;
    }
`;

export const Box = styled.div`
    width: 100%;
    padding: 2rem;
    border: 1px solid ${props => props.theme.colors.gray_light};
    border-radius: 7px;
    background-color: ${props => props.theme.colors.white};

    > p {
        margin-top: 1rem;
        color: ${props => props.theme.colors.gray_medium};
    }

    > form {
        > div {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media(max-width: 500px)
    {
        border-radius: 0;
    }
`;

export const Icon = styled.div`
    font-size: 60px;
    color: ${props => props.theme.colors.primary};
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TextMessage = styled.div`
    text-align: center;
    padding: 10px;
`;
