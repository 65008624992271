import styled from 'styled-components'

interface iButton {
    className?: 'primary' | 'secondary' | 'tertiary' | 'delete';
}

export const Container = styled.div`
    text-align: center;
    padding: 5px;
`;

export const Button = styled.button`
    background-color: ${props => props.theme.colors.primary};
    background: linear-gradient(90deg, ${props => props.theme.colors.primary} 0%, ${props => props.theme.colors.secondary} 100%);
    color: ${props => props.theme.colors.white};
    padding: 10px 16px 10px 16px;
    font-weight: 500;
    font-size: .95rem;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;

    :focus {
        box-shadow: 0 0 0 2px ${props => props.theme.colors.white}, 0 0 0 4px ${props => props.theme.colors.primary};
    }

    transition: box-shadow .3s;

    &&.secondary{
        background: none;
        background-color: ${props => props.theme.colors.secondary};
        border-color: ${props => props.theme.colors.secondary};
    }

    &&.tertiary{
        background: none;
        background-color: ${props => props.theme.colors.tertiary};
        border-color: ${props => props.theme.colors.tertiary};
        color: ${props => props.theme.colors.gray_dark};
    }

    &&.delete{
        background: none;
        background-color: ${props => props.theme.colors.white};
        border-color: ${props => props.theme.colors.warning};
        color: ${props => props.theme.colors.warning};

        &&:focus {
            box-shadow: none;
        }

        &&:hover {
            background-color: ${props => props.theme.colors.warning};
            color: ${props => props.theme.colors.white};
        }
    }
`;