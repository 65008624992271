import axios from 'axios';
import { apiBaseUrl, apiPrefix, apiVersion } from '../configs';

const api = axios.create({
    baseURL: apiBaseUrl+'/'+apiPrefix+'/'+apiVersion
});

export function withToken(token: string, spaceId: number){
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    api.defaults.headers.common['space-id'] = spaceId;

    return api;
}

export function publicApi(spaceId: number){
    api.defaults.headers.common['space-id'] = spaceId;

    return api;
}

export default api;