import styled from "styled-components";

interface iContainer {
    background?: string;
}

export const Container = styled.div<iContainer>`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.surface_5};
    background-image: ${props => props.background? `url(${props.background})` : ``};
    background-position: center;
    background-size: cover;
`;

export const LoginContainer = styled.div`
    width: 480px;
    display: flex;
    flex-direction: column;

    @media(max-width: 500px){
        width: 100%;
    }
`;

export const LogoContainer = styled.div`
    text-align: center;

    margin: 1rem;

    > img {
        max-height: 85px;
        max-width: 180px;
        width: auto;
        height: auto;
        margin: 0 auto 0 auto;
    }
`;

export const Box = styled.div`
    width: 100%;
    padding: 2rem;
    border: 1px solid ${props => props.theme.colors.gray_light};
    border-radius: 7px;
    background-color: ${props => props.theme.colors.white};

    > form {
        > div {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    @media(max-width: 500px){
        border-radius: 0px;
    }
`;

export const FromGroup = styled.div`
    margin: 10px 0 10px 0;
`;

export const ErrorMessage = styled.div`
    padding: 6px 0 6px 0;
    color: ${props => props.theme.colors.warning};
`;

export const ButtonArea = styled.div`
    > span {
        color: ${props => props.theme.colors.gray_medium};

        > a {
            text-decoration: none;
            color: ${props => props.theme.colors.primary+'F0'};
        }
    }   
    
    @media(max-width: 500px){
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        > span {
            margin-top: 20px;
        }
    }
`;

export const VersionBox = styled.div`
    font-size: .85rem;
    color: ${props => props.theme.colors.gray_light};
    padding: 12px 0 12px 32px;
`;
