import styled from "styled-components";

interface iInput {
    borderColor?: string;
    focussedBorderColor?: string;
    labelBold?: boolean;
    grayMode?: boolean;
}

export const Container = styled.label<iInput>`
    width: 100%;
    display: flex;
    flex-direction: column;

    > small {
        color: ${props => props.theme.colors.gray_medium};
        margin-top: 6px;
        line-height: 1.3rem;
    }

    > span {
        font-size: .95rem;
        padding-bottom: .5rem;
        padding-left: 2px;
        font-weight: ${props => props.labelBold? `bold` : '400'};
    }

    > input {
        padding: .75rem;
        border: 1px solid ${props => props.borderColor? props.borderColor : props.theme.colors.gray_light};
        box-shadow: 0px 0px 2px ${props => props.theme.colors.gray_light};
        border-radius: 7px;
        font-size: .95rem;

        background-color: ${props  => props.grayMode? props.theme.colors.surface_25+'35' : props.theme.colors.white};

        :focus {
            border: 1px solid ${props => props.focussedBorderColor? props.focussedBorderColor : props.theme.colors.primary};
            box-shadow: 0 0 0 1px ${props => props.focussedBorderColor? props.focussedBorderColor : props.theme.colors.primary};
        }

        transition: box-shadow .3s ease-in-out;
        transition: border .3s;
    }
`;