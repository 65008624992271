import styled from 'styled-components'

export const Container = styled.button`
    padding: .75rem .95rem .75rem .95rem;
    font-size: 1.06rem;
    font-weight: 600;
    background-color: ${props => props.theme.colors.white};
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    color: ${props => props.theme.colors.primary+'F0'};

    :hover{
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.primary};
    }

    :focus{
        box-shadow: 0 0 0 2px ${props => props.theme.colors.white}, 0 0 0 4px ${props => props.theme.colors.primary+'95'};
    }

    transition: .3s;
`;