import React from "react"

const SupportIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.7" height="12.599" viewBox="0 0 17.7 12.599" {...props}>
        <g id="_12937133821582634762" data-name="12937133821582634762" transform="translate(0.35 -54.467)">
            <path id="Caminho_1" data-name="Caminho 1" d="M16.554,55.214a1.556,1.556,0,0,0-1.072-.4H1.518a1.556,1.556,0,0,0-1.072.4A1.235,1.235,0,0,0,0,56.169v9.194a1.235,1.235,0,0,0,.446.955,1.555,1.555,0,0,0,1.072.4H15.482a1.555,1.555,0,0,0,1.072-.4A1.235,1.235,0,0,0,17,65.363V56.169A1.235,1.235,0,0,0,16.554,55.214Zm-.769,10.149a.247.247,0,0,1-.09.19.31.31,0,0,1-.213.08H1.518a.31.31,0,0,1-.213-.08.247.247,0,0,1-.09-.19v-6.49a5.334,5.334,0,0,0,.655.558q2.542,1.741,4.041,2.856.484.363.787.566a4.544,4.544,0,0,0,.821.41,2.619,2.619,0,0,0,.972.207h.019a2.619,2.619,0,0,0,.972-.207,4.538,4.538,0,0,0,.821-.41q.3-.2.787-.566,1.5-1.116,4.041-2.856a5.307,5.307,0,0,0,.654-.558v6.49Zm0-8.974v.093a1.965,1.965,0,0,1-.441,1.111,4.157,4.157,0,0,1-.953.976q-1.831,1.285-3.8,2.679l-.332.249q-.275.207-.436.317T9.4,62.08a2.644,2.644,0,0,1-.479.232,1.247,1.247,0,0,1-.408.076H8.49a1.248,1.248,0,0,1-.408-.076A2.64,2.64,0,0,1,7.6,62.08q-.261-.156-.422-.266T6.745,61.5l-.332-.249q-1.973-1.394-3.8-2.679a2.871,2.871,0,0,1-1.395-2.4.247.247,0,0,1,.09-.19.31.31,0,0,1,.213-.08H15.482a.182.182,0,0,0,.133.021c.038-.011.066.01.085.063s.036.079.052.076.025.032.029.106,0,.11,0,.11v.114Z" transform="translate(0 0)" strokeWidth="0.7"/>
        </g>
    </svg>
)

export default SupportIcon;