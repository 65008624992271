import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './style';

import { useTheme } from '../../contexts/theme';

const LoadingContainer: React.FC = () => {
    const  {theme}  = useTheme();
  return (
    <Container>
        <ReactLoading type="bubbles" color={theme.colors.primary} height="1.3rem"/>
    </Container>
  );
}

export default LoadingContainer;