import jwt, {JwtPayload} from "jwt-decode";

export function getRemainingTime(token: string): number
{
    const now = new Date();
    let tNow = now.getTime()

    let dToken = jwt(token) as JwtPayload
    let exp = dToken.exp? dToken.exp*1000 : tNow+1001
    
    return exp - tNow
}