import styled from "styled-components";

interface iContainer {
    backgroundImage?: string | null;
    showShadow?: boolean;
}

export const Container = styled.div<iContainer>`
    min-width: 250px;
    background-image: ${props => props.backgroundImage && (props.backgroundImage !== null)? `url("${props.backgroundImage}")` : `none`};
    background-repeat: no-repeat;
    background-size: cover;

    overflow-y: auto;

    ::-webkit-scrollbar{
        width: 16px;
    }

    ::-webkit-scrollbar-thumb{
        background-color: ${props => props.theme.colors.gray_medium};
        border-radius: 8px;
        border: 1px solid ${props => props.theme.colors.gray_light};
    }

    ::-webkit-scrollbar-track{
        background-color: ${props => props.theme.colors.gray_light};
    }

    @media(max-width: 988px){
        width: 100%;
        overflow: hidden;
        min-height: 64px;
        position: fixed;
        top: 0;
        left: 0;
    }

    z-index: 2;
`;

export const ColoredBackground = styled.div`
    display: flex;
    flex-direction: column;

    min-height: 100%;
    height: auto;
    background-color: ${props => String(props.theme.colors.nav_1+"de")};
    background: ${props => `linear-gradient(180deg, ${props.theme.colors.nav_1+"cc"} 0%, ${props.theme.colors.nav_2} 100%)`};

    @media(max-width: 988px){
        background: ${props => `linear-gradient(90deg, ${props.theme.colors.nav_1+"cc"} 0%, ${props.theme.colors.nav_2} 100%)`};
    }
`;

export const SideBarFooter = styled.div`
    @media(max-width: 988px){
        display: none;
    }
`;