import React, { createContext, useContext, useState } from 'react';
import { apiFolder, apiSpace } from '../types/api';
import { spaceId } from '../configs';
import breadcrub from '../types/breadcrumb';
import changePageTitle from '../utils/changePageTitle';

interface iNavContext {
    setCrumb(level: number, path: string, title: string): void;
    breadcrumbs: breadcrub[];
    space: apiSpace;
    setNavSpace(space: apiSpace): void;
    selectedMenu: string;
    setSelectedMenu(tag: string): void; 
    openedMenu: boolean;
    setOpenedMenu(state: boolean): void;
    selectedFolder: string;
    setSelectedFolder(value: string): void;
    openedSubmenu: boolean;
    setOpenedSubmenu(state: boolean): void;
    openedSearchBar: boolean;
    setOpenedSearchBar(state: boolean): void;
    showSearchBarToogle: boolean;
    setShowSearchBarToogle(state: boolean): void;
    spaceFolders: apiFolder[];
    setSpaceFolders(spaceFolders: apiFolder[]): void;
}

const NavContext = createContext<iNavContext>({} as iNavContext)

const NavProvider: React.FC = ({ children }) => {

    const storageKeyBreadcrumb = "@g3members"+spaceId+"_crumbs"
    const storageKeySpace = "@g3members"+spaceId+"_space"

    const [breadcrumbs, setBreadcrumbs] = useState(getStorageCrumbs())
    const [space, setSpace] = useState(getStorageSpace())
    const [selectedMenu, setSelectedMenu] = useState('content')
    const [openedMenu, setOpenedMenu] = useState(false)
    const [openedSearchBar, setOpenedSearchBar] = useState(false)
    const [showSearchBarToogle, setShowSearchBarToogle] = useState(false)
    const [spaceFolders, setSpaceFolders] = useState<apiFolder[]>([])

    const [selectedFolder, setSelectedFolder] = useState('')
    const [openedSubmenu, setOpenedSubmenu] = useState(false)

    function setCrumb(level: number, path: string, title: string){
        let crumbs = breadcrumbs.slice(0, level)
        crumbs.push({level: level, path: path, title: title})
        setBreadcrumbs(crumbs)
        localStorage.setItem(storageKeyBreadcrumb, JSON.stringify(crumbs))

        if(level > 0)
            newPageTitle(space.name, title)
        else
            newPageTitle(space.name)
            
    }

    function getStorageCrumbs(): breadcrub[]
    {
        let crumbs = localStorage.getItem(storageKeyBreadcrumb)
        if(crumbs !== null){
            return JSON.parse(crumbs) as breadcrub[]
        }

        return [] as breadcrub[]
    }

    function setNavSpace(nSpace: apiSpace){
        localStorage.setItem(storageKeySpace, JSON.stringify(nSpace))
        setSpace(nSpace)
    }

    function getStorageSpace(): apiSpace
    {
        let sSpace = localStorage.getItem(storageKeySpace)
        if(sSpace !== null){
            return JSON.parse(sSpace) as apiSpace
        }

        return {} as apiSpace
    }

    function newPageTitle(title: string, subtitle: string | null = null){
        if(subtitle === null)
            changePageTitle(title)
        else
            changePageTitle(subtitle+" • "+title)
    }

    return (
        <NavContext.Provider value={{ 
            setCrumb, 
            breadcrumbs, 
            space, 
            setNavSpace, 
            selectedMenu, 
            setSelectedMenu,
            openedMenu,
            setOpenedMenu,
            openedSearchBar,
            setOpenedSearchBar,
            showSearchBarToogle,
            setShowSearchBarToogle,
            spaceFolders,
            setSpaceFolders,
            openedSubmenu,
            setOpenedSubmenu,
            selectedFolder,
            setSelectedFolder
         }}>
            { children }
        </NavContext.Provider>
    )
}

function useNav(): iNavContext {
    const context = useContext(NavContext)
    return context
}

export { NavProvider, useNav };