import React, {useEffect} from "react";
import { HashRouter } from "react-router-dom";

import AppRoutes from "./app.routes";
import AuthRoutes from "./auth.routes";

import { useAuth } from "../contexts/auth";
import LoadingContainer from "../components/LoadingContainer";

const Routes: React.FC = () => {
    const { token, vefToken, waiting } = useAuth();

    useEffect(() => {
        vefToken()
    },[])

    return(
        <HashRouter>
            {
                waiting? <LoadingContainer /> :
                    token === ''? <AuthRoutes /> : <AppRoutes />
            }
            
        </HashRouter>
    )
}

export default Routes;