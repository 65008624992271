import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { Container, TopLogo, ToogleButton, Avatar, Footer, ToogleContainer, SerachButton } from './style';
import ProfileAvatar from "../ProfileAvatar";
import Border from "../Border";

import { useTheme } from "../../contexts/theme";
import { HiOutlineMenu } from 'react-icons/hi';

import { useAuth } from '../../contexts/auth';
import { useNav } from "../../contexts/nav";
import { apiBaseUrl } from '../../configs';
import resolveAvatarImage from "../../utils/resolveAvatarImage";

import { BiSearch } from "react-icons/bi";

interface iSideBarHeader {
    onTootle(): void;
}

const SideBarHeader: React.FC<iSideBarHeader> = ( props ) => {

    const history = useHistory()

    const { theme } = useTheme()
    const { member, logout } = useAuth()
    const { openedSearchBar, setOpenedSearchBar, showSearchBarToogle} = useNav()

    function toogleSearchBar()
    {
        setOpenedSearchBar(!openedSearchBar)
    }

    function showLogo(url: string, alt: string){
        return (
            
                <TopLogo>
                    <Link to="/">
                    <img src={url} alt={alt} />
                    </Link>
                </TopLogo>
            
        )
    }

    function myAccount()
    {
        history.push('/profile')
    }

    return (
        <>
        <Container>
            <ToogleContainer>
                <ToogleButton onClick={ props.onTootle }>
                    <HiOutlineMenu />
                </ToogleButton>
                {
                    showSearchBarToogle&&
                    <SerachButton onClick={toogleSearchBar}>
                        <BiSearch />
                    </SerachButton>
                }                
            </ToogleContainer>
            
                {theme.images.logo !== null && showLogo(apiBaseUrl+theme.images.logo,"")}
            <Avatar >
                <ProfileAvatar avatar={resolveAvatarImage(member)} size={40} action={myAccount}/>
            </Avatar>
        </Container>
        <Footer>
            <Border />
        </Footer>
        </>
    )
}

export default SideBarHeader;