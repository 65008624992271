import React, { useEffect } from "react";
import ProfileWidget from "../ProfileWidget";
import SideBarHeader from "../SideBarHeader";
import SideMenu from "../SideMenu";
import { useTheme } from '../../contexts/theme';
import { apiBaseUrl } from "../../configs";
import { useNav } from "../../contexts/nav";
import { withToken } from "../../services/api";

import { Container, ColoredBackground, SideBarFooter } from "./style";
import { useAuth } from "../../contexts/auth";

const SideBar: React.FC = () => {

    const { openedMenu, setOpenedMenu, spaceFolders, setSpaceFolders } = useNav()
    const { theme } = useTheme();
    const { totalComments, token, spaceId } = useAuth()

    const api = withToken(token, spaceId)

    useEffect(() => {
        if(spaceFolders.length === 0) {
            api.get('space/folders').then(
                response => {
                    setSpaceFolders(response.data.data)
                }
            )
        }
    },[api])

    const toogleMenu = () => {
        setOpenedMenu(!openedMenu);
    }

    function onSelectMenuOption(key: string)
    {
        setOpenedMenu(false)
    }

    return (
        <Container 
            backgroundImage={theme.images.background_menu&& apiBaseUrl+theme.images.background_menu}
            showShadow={openedMenu}>
            <ColoredBackground>
                <SideBarHeader onTootle={ toogleMenu }/>
                <SideMenu 
                    isOpen={ false }
                    commentsBadge={totalComments.pending}
                    onSelect={onSelectMenuOption}
                    folders={spaceFolders}/>
                <SideBarFooter>
                    <ProfileWidget />
                </SideBarFooter>
            </ColoredBackground>
        </Container> 
    )
}

export default SideBar;