import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.white};
    height: 100vh;
    width: 100%;
    flex: 1;
    max-width: calc(100vw - 250px);

    @media(max-width: 988px){
        max-width: 100vw;
    }

    ::-webkit-scrollbar{
        width: 16px;
    }

    ::-webkit-scrollbar-thumb{
        background-color: ${props => props.theme.colors.gray_medium};
        border-radius: 8px;
        border: 1px solid ${props => props.theme.colors.gray_light};
    }

    ::-webkit-scrollbar-track{
        background-color: ${props => props.theme.colors.gray_light};
    }
`;