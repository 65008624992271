import React from 'react';

import { Container, Button } from './style';

interface iThemeButton {
    onClick?(): void;
    className?: 'primary' | 'secondary' | 'tertiary' | 'delete';
}

const ThemeButton: React.FC<iThemeButton> = ( props ) => {
  return (
    <Container onClick={props.onClick} className={props.className}>
        <Button className={props.className}>{ props.children }</Button>
    </Container>
  );
}

export default ThemeButton;