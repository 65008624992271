import React from "react";

import { Container, MenuList, Title } from "./style";
import { 
    HiUsers,
    HiChatAlt2,
    HiAdjustments,
    HiOutlineCloud }
    from 'react-icons/hi/';

import { FaPaintBrush } from 'react-icons/fa';

import { ImFileText2 } from 'react-icons/im';

import SideMenuItem from "../SideMenuItem";
import { useNav } from "../../contexts/nav";
import { useAuth } from "../../contexts/auth";
import memberLevels from "../../enums/memberLevels";

import CategoryIcon from "../Icons/CategoriesIcon";
import RankingIcon from "../Icons/RankingIcon";
import AccountIcon from "../Icons/AccountIcon";
import SupportIcon from "../Icons/SupportIcon";
import { apiFolder } from "../../types/api";

interface iSideMenu {
    isOpen: boolean;
    commentsBadge?: number;
    onSelect?(key: string): void;
    folders?: apiFolder[];
}

const SideMenu: React.FC<iSideMenu> = ( props ) => {

    const { member } = useAuth()
    const { selectedMenu, selectedFolder, setSelectedFolder, spaceFolders } = useNav()

    function handleSelect(key: string)
    {
        props.onSelect&&
            props.onSelect(key)
    }

    function handleSelectSubitem(key: string) {
        setSelectedFolder(key)
        props.onSelect&&
            props.onSelect(key)
    }

    return (
        <Container isOpen={ props.isOpen }>
            <Title> { member.level > memberLevels.ATTENDANCE ? 'ADMINISTRAÇÃO' : 'ÁREA DE MEMBROS'}</Title>
                {
                    member.level === memberLevels.MEMBER && (
                        <MenuList>
                            {
                                spaceFolders.length > 1 && member.level === memberLevels.MEMBER &&
                                    <SideMenuItem 
                                        href="/" 
                                        title="Categorias" 
                                        active={selectedMenu === 'categories'? true : false} 
                                        itemKey='categories'
                                        onClick={handleSelect}>
                                            <CategoryIcon />
                                    </SideMenuItem>
                            }
                            
                            <SideMenuItem 
                                title="Conteúdo" 
                                active={selectedMenu === 'content'? true : false} 
                                itemKey='content'
                                subitems={
                                    props.folders?.map((item, i) => {return {text: item.name, value: item.slug}})
                                }
                                activeSubitem={selectedFolder}
                                onSubitemClick={handleSelectSubitem}>
                                    <ImFileText2 />
                            </SideMenuItem>
                            <SideMenuItem 
                                href="/ranking" 
                                title="Ranking" 
                                active={selectedMenu === 'ranking'? true : false} 
                                itemKey='ranking'
                                onClick={handleSelect}>
                                    <RankingIcon />
                            </SideMenuItem>
                            <SideMenuItem 
                                href="/account" 
                                title="Minha conta" 
                                active={selectedMenu === 'account'? true : false} 
                                itemKey='account'
                                onClick={handleSelect}>
                                    <AccountIcon />
                            </SideMenuItem>
                            <SideMenuItem 
                                href="/support" 
                                title="Atendimento" 
                                active={selectedMenu === 'support'? true : false} 
                                itemKey='support'
                                onClick={handleSelect}>
                                    <SupportIcon />
                            </SideMenuItem>
                        </MenuList>
                    ) 
                }

                {
                    member.level > memberLevels.MEMBER && (
                        <>
                        <MenuList>
                            <SideMenuItem 
                                href="/" 
                                title="Categorias" 
                                active={selectedMenu === 'categories'? true : false} 
                                itemKey='categories'
                                onClick={handleSelect}>
                                    <CategoryIcon />
                            </SideMenuItem>
                            <SideMenuItem 
                                title="Conteúdo" 
                                active={selectedMenu === 'content'? true : false} 
                                itemKey='content'
                                subitems={
                                    props.folders?.map((item, i) => {return {text: item.name, value: item.slug}})
                                }
                                activeSubitem={selectedFolder}
                                onSubitemClick={handleSelectSubitem}>
                                    <ImFileText2 />
                            </SideMenuItem>

                            {
                                member.level > memberLevels.ATTENDANCE&&
                                <SideMenuItem 
                                    href="/members" 
                                    title="Membros" 
                                    active={selectedMenu === 'members'? true : false} 
                                    itemKey='members'
                                    onClick={handleSelect}>
                                    <HiUsers />
                                </SideMenuItem>
                            }
                            {
                                member.level > memberLevels.MEMBER&&
                                <SideMenuItem 
                                    href="/community" 
                                    title="Comunidade" 
                                    active={selectedMenu === 'community'? true : false} 
                                    itemKey='community'
                                    badge={props.commentsBadge}
                                    onClick={handleSelect}>
                                    <HiChatAlt2 />
                                </SideMenuItem>
                            }

                            {
                                member.level > memberLevels.MODERATOR&&
                                <>
                                    <SideMenuItem 
                                        href="/theme" 
                                        title="Aparência" 
                                        active={selectedMenu === 'theme'? true : false} 
                                        itemKey='theme'
                                        onClick={handleSelect}>
                                        <FaPaintBrush />
                                    </SideMenuItem>
                                    <SideMenuItem 
                                        href="/configs" 
                                        title="Configurações" 
                                        active={selectedMenu === 'configs'? true : false} 
                                        itemKey='configs'
                                        onClick={handleSelect}>
                                        <HiAdjustments />
                                    </SideMenuItem>
                                    <SideMenuItem 
                                        href="/integrations" 
                                        title="Integrações" 
                                        active={selectedMenu === 'integrations'? true : false} 
                                        itemKey='integrations'
                                        onClick={handleSelect}>
                                        <HiOutlineCloud />
                                    </SideMenuItem>
                                </>
                                
                            }                            
                        </MenuList>                     
                        </>
                    )
                }
        </Container>
    )
}

export default SideMenu;