import styled from "styled-components";

export const Container = styled.div`
    padding-top: 36px;
    padding-left: 16px;
    padding-right: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 988px){
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 64px;
    }
`;

export const ToogleButton = styled.button`
    color: ${props => props.theme.colors.white};
    background: none;
    height: 24px;
    text-align: left;
    line-height: 24px;
    font-size: 24px;

    display: none;

    @media(max-width: 988px){
        display: inline;
    }
`;

export const TopLogo = styled.div`
    text-align: center;
    flex: 1;

    > a {
        > img {
            max-height: 85px;
            max-width: 180px;
            width: auto;
            height: auto;
        }
    }

    @media(max-width: 988px){
        > a {
            > img {
                height: 45px;
            }
        }
    }
`;

export const Avatar = styled.div`
    display: none;

    @media(max-width: 988px){
        display: inline;
    }
`;

export const Footer = styled.div`
    display: none;

    @media(max-width: 988px){
        display: block;
    }
`;

export const ToogleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

export const SerachButton = styled.div`
    font-size: 1.5rem;

    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.white};
    cursor: pointer;

    @media(min-width: 988px){
        display: none;
    }

    position: absolute;
    left: 50px;
`;