import React, { FormEvent, useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import InputLabel from "../../components/InputLabel";

import { Container, FormContainer, Box, Icon, TextMessage } from "./style";
import { useTheme } from "../../contexts/theme";
import Button from "../../components/Button";
import { apiBaseUrl } from "../../configs";
import { publicApi } from "../../services/api";
import { spaceId, localUrl } from "../../configs";
import { useMessages } from "../../contexts/messages";
import LoadingContainer from "../../components/LoadingContainer";
import { HiOutlineMail } from "react-icons/hi";
import ThemeButton from "../../components/ThemeButton";

const Login: React.FC = () => {

    let { redefined } = useParams<{redefined?: string;}>();

    const inputRef = useRef<HTMLInputElement>(null)

    const { theme } = useTheme();
    const api = publicApi(spaceId)
    const { showModalMessage } = useMessages()
    const history = useHistory()

    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        inputRef.current?.focus();
    },[])

    function redefine(event: FormEvent){
        setLoading(true)
        let data = {
            email: email,
            callback_uri: localUrl+"/#/forgot-password/redefined"
        }

        console.log(data)

        api.post('reset_password', data).then(
            () => {
                showModalMessage("Um e-mail de redefinição de senha foi enviado para "+email+", verifique sua caixa de entrada.", "Solicitação de redefinição de senha")
                history.push('')
            }
        ).catch(
            error => {
                if(error.response.status === 404)
                    showModalMessage("O endereço de e-mail "+email+" não foi encontrado em nossa base de dados. Verifique se digitou corretamente e tente novamente.", "E-mail não encontrado")
                else
                    showModalMessage("Ops!","Erro inesperado.")
            }
        ).finally(
            () => setLoading(false)
        )
        event.preventDefault();
    }

    function goLogin()
    {
        history.push("/")
    }

    function showForm()
    {
        return (
            <FormContainer>
                <Box>
                    <h3>Esqueceu sua senha?</h3>
                    <p>
                        Digite seu endereço de e-mail e nós enviaremos a redefinição de senha para sua caixa de entrada.
                    </p>
                    <form onSubmit={(e) => redefine(e)}>
                        <br />
                        <InputLabel reference={inputRef} type="email" required value={email} onChange={setEmail}>E-mail</InputLabel>
                        <div>
                            <Button
                                borderColor={theme.colors.primary}
                                fontColor={theme.colors.primary}
                                borderWeight={2}
                                width="100%"
                                textAlign="center"
                            >
                            Enviar e-mail de redefinição de senha</Button>
                        </div>
                    </form>
                </Box>
            </FormContainer>
        )
    }

    function showRedefinedMessage()
    {
        return (
            <FormContainer>
                <Box>
                    <Icon>
                        <HiOutlineMail />
                    </Icon>
                    <TextMessage>
                        <h3>Senha redefinida</h3>
                        <p>
                            Um e-mail foi enviado a você com a nova senha de acesso.
                        </p>
                    </TextMessage>
                    <ThemeButton onClick={goLogin}>
                        Fazer login
                    </ThemeButton>
                </Box>
            </FormContainer>
        )
    }

    return (
        <Container background={theme.images.background_login!=null? apiBaseUrl+theme.images.background_login : undefined}>
            {loading?
                <LoadingContainer /> : redefined? showRedefinedMessage() : showForm()
            }            
        </Container>
    )
}

export default Login;