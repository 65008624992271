import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from 'react-icons/io';

import { Container, Badge, Submenu } from './style';

type subitem = {
    text: string;
    value: string;
}

interface iSideMenuItem{
    title: string;
    href?: string;
    active: boolean;
    onClick?: (value: string) => void;
    itemKey: string;
    badge?: number;
    subitems?: subitem[];
    onSubitemClick?: (value: string) => void;
    activeSubitem?: string;
}

const SideMenuItem: React.FC<iSideMenuItem> = ( props ) => {

    const [submenuOpen, setSubmenuOpen] = useState(false)
    const [firstTime, setFirstTime] = useState(true)

    const setActiveItem = (value: string) => {
        props.onClick&& props.onClick(value);
    }

    function handleOpen() {
        firstTime&& setFirstTime(false)
        setSubmenuOpen(!submenuOpen)
    }

    function showLink() {
        return (
            <Link to={props.href? props.href : ''} className={props.active ? 'active' : ''} onClick={() => setActiveItem(props.itemKey)}>
                <div>
                   {props.children}
                    <span>{props.title}</span>
                    {props.badge? <Badge>{props.badge}</Badge> : <></>} 
                </div>                
            </Link>
        )
    }

    function showMenu() {
        return(
            <>
                <a onClick={() => setSubmenuOpen(!submenuOpen)} className={props.active ? 'active' : ''}>
                    <div onClick={handleOpen}>
                        {props.children}
                        <span>{props.title}</span>
                        {props.badge? <Badge>{props.badge}</Badge> : <></>} 
                    </div>
                </a>
                <Submenu 
                    length={props.subitems? props.subitems.length : 0} 
                    open={submenuOpen}
                    firstTime={firstTime}
                >
                    {
                        props.subitems?.map((item) => 
                            <div 
                                key={`sub_${item.value}`}
                                className={
                                    props.activeSubitem? 
                                        props.activeSubitem === item.value? 'active' : ''
                                    : ''
                                }
                                onClick={() =>  props.onSubitemClick&& props.onSubitemClick(item.value)}
                                >
                                <div className="icon"><IoIosArrowForward /></div>
                                <div className="text">
                                    <Link to={{ pathname: "/"+item.value}}>{item.text}</Link>
                                </div>
                            </div>
                        )
                    }
                    
                </Submenu>
            </>
            
        )
    }

    return (
        <Container>
            {
                props.subitems?
                    props.subitems.length > 0 ? showMenu() : showLink() 
                : showLink()
            }
        </Container>
    )
}

export default SideMenuItem;