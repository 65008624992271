import { apiTheme } from "../types/api";
import theme from "../types/theme";

export function convertTheme(lTheme: apiTheme): theme{
    let theme = {
        id: lTheme.id,
        title: lTheme.title,
        colors: {
            primary: lTheme.color_primary,
            secondary: lTheme.color_secondary,
            tertiary: lTheme.color_tertiary,
            nav_1: lTheme.color_nav_1,
            nav_2: lTheme.color_nav_2,
            white: lTheme.color_white,
            black: lTheme.color_black,
            gray_light: lTheme.color_gray_light,
            gray_25: lTheme.color_gray_25,
            gray_medium: lTheme.color_gray_medium,
            gray_75: lTheme.color_gray_75,
            gray_dark: lTheme.color_gray_dark,
            success: lTheme.color_success,
            info: lTheme.color_info,
            warning: lTheme.color_warning,
            surface_5: lTheme.color_surface_5,
            surface_25: lTheme.color_surface_25,
            surface_50: lTheme.color_surface_50,
            theater: lTheme.color_theater,
        },
        images: {
            icon: lTheme.image_icon,
            logo: lTheme.image_logo,
            background_login: lTheme.image_background_login,
            background_menu: lTheme.image_background_menu,
        }
    }

    return theme
}