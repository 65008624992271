import styled from "styled-components";

export const Container = styled.div`
    margin-top: 2rem;
`;

export const Content = styled.div`
    color: ${props => props.theme.colors.white};

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 25px 45px 25px 45px;
    font-size: 1.2rem;

    > .icon {
        opacity: 0.6;
    }

    > .small {
        font-size: 1rem;
    }

    > .avatar{
        box-shadow: 0 0 0 2px ${props => props.theme.colors.white};
        border-radius: 50%;
        width: 3.2rem;
    }
`;

export const Links = styled.div`
    width: 100%;
    height: 3.5rem;
    margin-top: 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > a {
        display: inline-block;
        text-decoration: none;
        color: ${props => props.theme.colors.white};
        font-size: .8rem;
        font-weight: 400;
    }
`;