import theme from "./types/theme"

const local_theme = {
    id: 0,
    title: "default_local",
    colors: {
        primary: '#999',
        secondary: '#ccc',
        tertiary: '#f9fbfd',
        nav_1: '#111827',
        nav_2: '#111827',
        white: '#ffffff',
        black: '#181818',
        gray_light: '#e0e0e0',
        gray_25: '#959595',
        gray_medium: '#6b7280',
        gray_75: '#616161',
        gray_dark: '#2e2f31',
        success: '#10b981',
        info: '#f7931b',
        warning: '#e44c4e',
        surface_5: '#f9fbfd',
        surface_25: '#f9fbfd',
        surface_50: '#f9fbfd',
        theater: '#111827',
    },
    images: {
        icon: null,
        logo: null,
        background_login: null,
        background_menu: 'https://images.ecycle.com.br/wp-content/uploads/2021/05/20195924/o-que-e-paisagem.jpg.webp?v=1',
    }
  } as theme

  export default local_theme