import React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from './GlobalStyle';
import Routes from './routes';
import { useTheme } from './contexts/theme';
import { MessagesProvider } from './contexts/messages';
import { NavProvider } from './contexts/nav';

const App: React.FC = () => {
  
  const {theme} = useTheme();

  return (
    <NavProvider>
      <ThemeProvider theme={ theme }>
        <MessagesProvider>
          <GlobalStyle />
          <Routes />
        </MessagesProvider>
      </ThemeProvider>
    </NavProvider>
  );
}

export default App;