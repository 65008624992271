import React from "react";

import { Container } from "./style";

const Content: React.FC = ( props ) => {
    return (
        <Container>
            { props.children }
        </Container>
    )
}

export default Content;