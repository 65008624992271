import React, { FormEvent, useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";


import { Container, LoginContainer, Box, LogoContainer, FromGroup, ErrorMessage, ButtonArea, VersionBox } from "./style";
import InputLabel from "../../components/InputLabel";

import { useTheme } from "../../contexts/theme";
import { useAuth } from "../../contexts/auth";
import { useNav } from "../../contexts/nav";

import { publicApi } from "../../services/api";
import { apiBaseUrl } from "../../configs";
import ButtonLarge from "../../components/ButtonLarge";
import { apiSpace } from "../../types/api";
import { convertTheme } from "../../utils/convertTheme";
import LoadingContainer from "../../components/LoadingContainer";
import theme from "../../types/theme";
import { version } from "../../configs";

const Login: React.FC = () => {

    const refPassword = useRef<HTMLInputElement>(null)
    const refEmail = useRef<HTMLInputElement>(null)

    const { setNavSpace, setCrumb } = useNav();
    const { theme, aplyTheme } = useTheme();
    const { login, error, deleteError, spaceId, waiting, lastEmail } = useAuth();

    const [email, setEmail] = useState(lastEmail);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);

    function handleLogin(event: FormEvent){
        login(email, password);
        event.preventDefault();
    }

    useEffect(() => {
        loadSpace(spaceId)
        setCrumb(0,'','')
        return () => deleteError()
    },[])

    useEffect(() => {
        setEmail(lastEmail)
    },[lastEmail])

    useEffect(() => {
        if(email.length > 1)
            refPassword.current?.focus()
        else
            refEmail.current?.focus()
    }, [loading])    

    function loadSpace(id: number){
        const api = publicApi(id)
        api.get('this?relationships=theme').then(
            response => {
                let lSpace = response.data as apiSpace
                lSpace.themes&&
                    lSpace.themes.length > 0 &&
                        changeTheme(convertTheme(lSpace.themes[0]))
                setNavSpace(lSpace)
                setLoading(false)
                setCrumb(0,'','')
            }
        ).catch(
            error => {
                alert("Não foi possível se conectar ao servidor.")
                console.log(error)
            }
        )
    }

    function changeTheme(lTheme: theme){
        aplyTheme(lTheme)
    }

    function showLogo(url: string, alt: string = ""){
        return (
            <LogoContainer>
                <img src={url} alt={alt} />
            </LogoContainer>
        )
    }

    const errorMessageText = useMemo(() => {
        switch(error.code)
        {
            case null: return undefined;
            case 404: return "E-mail não encontrado";
            case 403: return "Senha incorreta. Verifique se digitou corretamente.";
            case 402: return "Membro bloqueado.";
            default: return "Erro no serviço de login. Tente novamente mais tarde.";
        }
    },[error])

    function showForm(){
        
        return (
            
            <Container background={theme.images.background_login!=null? apiBaseUrl+theme.images.background_login : undefined}>
                {
                    waiting? <LoadingContainer /> :
                    <LoginContainer>
                        {theme.images.logo !== null && showLogo(apiBaseUrl+theme.images.logo)}
                        <Box>
                            { errorMessageText&& <ErrorMessage>{errorMessageText}</ErrorMessage> }
                            <form onSubmit={(e) => handleLogin(e)}>
                                <FromGroup>
                                    <InputLabel 
                                        type="email"
                                        required value={email}
                                        onChange={setEmail}
                                        reference={refEmail}>E-mail</InputLabel>
                                </FromGroup>
                                <FromGroup>
                                    <InputLabel 
                                        type="password"
                                        required value={password} 
                                        onChange={setPassword}
                                        reference={refPassword}>Senha</InputLabel>
                                </FromGroup>
                                <ButtonArea>
                                    <span>Esqueceu sua senha? <Link to="/forgot-password">Clique aqui</Link></span>
                                    <ButtonLarge>login</ButtonLarge>
                                </ButtonArea>
                            </form>
                        </Box>
                        <VersionBox>Versão: {version}</VersionBox>
                    </LoginContainer>
                }
                
            </Container>
        )
    }

    return loading ? <LoadingContainer /> : showForm()
}

export default Login;