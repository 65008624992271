import styled, { keyframes } from "styled-components";

export const Container = styled.li`
    color: ${props => props.theme.colors.white};

    > a {
        text-decoration: none;
        color: ${props => props.theme.colors.white};
        cursor: pointer;

        > div {
            width: 100%;
            display: flex;
            align-items: center;
            padding: .7rem;

            > span {
                font-size: .85rem;
                margin-left: 10px;
            } 
            
            > svg {
                width: .75rem;
                fill: ${props => props.theme.colors.white};
            }
        }

        transition: opacity .3s;
    }

    > a.active {
        cursor: pointer;
        > div {
            background-color:  ${props => props.theme.colors.white};
            border-radius: 5px;
            color: ${props => props.theme.colors.primary};

            > svg {
                fill: ${props => props.theme.colors.primary};
            }
        }
    }
`;

export const Badge = styled.div`
    padding: 3px 6px 3px 6px;
    border-radius: 3px;
    font-size: .7rem;
    font-weight: 600;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primary};
    margin-left: 12px;
`;

export const dropDown = (down: boolean, length: number) => keyframes`
    from {
        height: ${down? `0px` : `calc(1.85rem + (${length}*(22.8px)))`};
    }

    to {
        height: ${down? `calc(1.85rem + (${length}*(22.8px)))` : '0px'};
    }
`;

interface iSubmenu {
    firstTime: boolean;
    length: number;
    open: boolean;
}

export const Submenu = styled.div<iSubmenu>`

    display: 'flex';
    flex-direction: column;
    justify-content: flex-start;
    padding: ${props => props.open? `.5rem 0 .5rem 1rem` : `0 0 0 1rem`};
    font-size: .8rem;

    width: 100%;
    height: ${props => props.open? `calc(1.85rem + (${props.length}*(22.8px)))` : '0px'};

    overflow: hidden;

    > div {
        width: 100%;
        display: flex;
        align-items: center;
        color: ${props => props.theme.colors.white};
        opacity: .7;

        > .text {
            flex: 1;
            font-size: inherit;
            padding: 3px 0;

            > a {
                text-decoration: none;
                color: ${props => props.theme.colors.white};
            }
        }

        > .icon {
            display: flex;
            align-items: center;
            font-size: inherit;
            margin-right: 1rem;
        }
    }

    > .active {
        font-size: .85rem;
        opacity: 1;
    }

    transition: padding .2s;
    animation: ${props => props.firstTime? 'none' : dropDown(props.open? props.open : false, props.length)} .2s linear;
`;