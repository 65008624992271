import React, { createContext, useContext, useState } from "react";

import DefaultTheme from "../DefaultTheme";
import iTheme from "../types/theme";
import changeFavicon, {} from "../utils/changeFavicon"
import { apiBaseUrl, storageKey } from "../configs"

interface iThemeContext {
    aplyTheme(theme: iTheme): void;
    theme: iTheme;
}

const ThemeContext = createContext<iThemeContext>({} as iThemeContext);

const ThemeProvider: React.FC = ({ children }) => {
    const storageKeyTheme = storageKey+"_theme"

    const [theme, setTheme] = useState(vefTheme());

    function vefTheme(): iTheme{
        let dTheme = DefaultTheme
            let lTheme = localStorage.getItem(storageKeyTheme)
            if(lTheme !== null){
                dTheme = JSON.parse(lTheme)
            }
            dTheme.images.icon !== null &&
                changeFavicon(apiBaseUrl+dTheme.images.icon)
        return dTheme
    }

    function aplyTheme(nTheme: iTheme){
        localStorage.setItem(storageKeyTheme, JSON.stringify(nTheme))
        setTheme(nTheme)
        nTheme.images.icon !== null &&
            changeFavicon(apiBaseUrl+nTheme.images.icon)            
    }

    return (
        <ThemeContext.Provider value={{ aplyTheme, theme}}>
            { children }
        </ThemeContext.Provider>
    )
}

function  useTheme(): iThemeContext {
    const context = useContext(ThemeContext);
    return context;
}

export { ThemeProvider, useTheme };