import styled from 'styled-components'

interface iContainer {
    visible?: boolean;
}

export const Container = styled.div<iContainer>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${props => props.theme.colors.gray_dark+'30'};
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${props => props.visible? 'visible' : 'hidden' };
    z-index: 5;
`;

interface iWindow {
    maxWidth?: string;
    maxHeight?: string;
}

export const Window = styled.div<iWindow>`
    width: 100%;
    max-width: ${props => props.maxWidth? props.maxWidth : '580px'};
    max-height: ${props => props.maxHeight? props.maxHeight : '80%'};;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.gray_light};
    box-shadow: 0 0 2px ${props => props.theme.colors.gray_light};
    border-radius: 7px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    

    @media(max-width: 612px){
        margin: 16px;
    }
    
`;

export const ContentArea = styled.div`
    flex: 1;
    overflow: auto;
    padding: 5px 5px 5px 5px;

    ::-webkit-scrollbar{
        width: 8px;
    }

    ::-webkit-scrollbar-thumb{
        background-color: ${props => props.theme.colors.gray_medium};
        border-radius: 4px;
        border: 1px solid ${props => props.theme.colors.white};
    }

    ::-webkit-scrollbar-track{
        background-color: ${props => props.theme.colors.white};
    }
`;


export const FooterArea = styled.div`
    height: auto;
`;