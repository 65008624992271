import styled from "styled-components";

export const Grid = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;

    @media(max-width: 988px){
        flex-direction: column;
        height: auto;
        position: relative;
        height: auto;
        padding-top: 65px;
    }
`;