import React from 'react';
import ButtonBar from '../ButtonBar';
import ThemeButton from '../ThemeButton';

import { Container, Window, ContentArea, FooterArea } from './style';

interface iModal {
    visible?: boolean;
    cancelButtonText?: string;
    confirmButtonText?: string;
    onCancel?(): void;
    onConfirm?(): void;
    onDelete?(): void;
    showDeleteButton?: boolean;
    hideCancelButton?: boolean;
    onClickOut?(): void;
    maxWidth?: string;
    maxHeight?: string;
}

const Modal: React.FC<iModal> = ( props ) => {

  function handleClickOut()
  {
    props.onClickOut&&
      props.onClickOut()
  }

  return (
    <Container visible={props.visible} onClick={e => {e.stopPropagation(); handleClickOut()}}>
        <Window maxWidth={props.maxWidth} maxHeight={props.maxHeight}>
            <ContentArea >
              { props.children }
            </ContentArea>
            <FooterArea onClick={e => {e.stopPropagation()}}>
              <ButtonBar hideTopLine topMargin="0px">
                <div>
                  {props.showDeleteButton&& <ThemeButton onClick={props.onDelete} className="delete">Excluir</ThemeButton>}
                </div>
                <div>
                  {
                    !props.hideCancelButton&&
                      <ThemeButton onClick={props.onCancel} className="tertiary">
                        {props.cancelButtonText? props.cancelButtonText : 'Cancelar'}  
                      </ThemeButton> 
                  }
                  
                  <ThemeButton onClick={props.onConfirm}>
                    {props.confirmButtonText? props.confirmButtonText : 'OK'} 
                  </ThemeButton>
                </div>                
              </ButtonBar>
            </FooterArea>
        </Window>
    </Container>
  );
}

export default Modal;