import styled from 'styled-components'

interface iContainer {
    visible: boolean;
}

interface iMessage {
    backgroundColor: string;
    fontColor: string;
}

export const Container = styled.div<iContainer>`
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    display: ${props => props.visible? 'flex' : 'none'};
    justify-content: center;
    z-index: 6;
`;

export const Message = styled.div<iMessage>`
    color: ${props => props.fontColor};
    background-color: ${props => props.backgroundColor};

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 20px;
    font-weight: 500;
    font-size: 1.1rem;

    width: 100%;
    max-width: 520px;
    z-index: 4;

    box-shadow: 0 0 4px 2px ${props => props.theme.colors.gray_light};

    text-align: center;
    border-radius: 0 0 12px 12px;

    > div {
        text-align: center;
    }
    
    transition: height .3s;
`;