import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import api, { withToken } from "../services/api";
import { apiMember, apiTotalComments } from "../types/api";
import { spaceId, storageKey } from "../configs";
import { getRemainingTime } from "../utils/tokenManager";
import memberLevels from "../enums/memberLevels";

type error = {
    code: number | null;
    message: string | null;
}

interface iAuthContext {
    token: string;
    member: apiMember;
    spaceId: number;
    error: error;
    successMessage: string;
    lastEmail: string;
    vefToken(): string,
    login(email: string, password: string): void;
    logout(callback?: () => void): void;    
    reloadMe(callback: (me: apiMember) => void): void;
    deleteError(): void;
    waiting: boolean;
    totalComments: apiTotalComments;
    setTotalComments(totals: apiTotalComments): void;
}

const AuthContext = createContext<iAuthContext>({} as iAuthContext);
const storageKeyToken = storageKey+spaceId+"_token"
const storageKeyMember = storageKey+spaceId+"_member"

const AuthProvider: React.FC = ({ children }) => {

    const [token, setToken] = useState('')
    const [member, setMember] = useState({} as apiMember)
    const [error, setError] = useState({code: null, message: null} as error)
    const [successMessage, setSuccessMessage] = useState("")
    const [waiting, setWaiting] = useState(false)
    const [totalComments, setTotalComments] = useState<apiTotalComments>({pending: 0} as apiTotalComments)
    const [lastEmail, setLastEmail] = useState('')
    const timer = useRef({} as NodeJS.Timeout)

    useEffect(()=> {
        return () => clearTimeout(timer.current)
    },[])

    function loadTotalComments(level: number, token: string){
        if(level > memberLevels.MEMBER){
            const tApi = withToken(token, spaceId)
            tApi.get('space/totals/comments').then(
                response => {
                    setTotalComments(response.data as apiTotalComments)
                }
            ).finally(
                () => setWaiting(false)
            )
        } else{
            setWaiting(false)
        }
    }

    function vefToken(): string
    {
        savedMember()
        let vToken = ''
        let lToken = localStorage.getItem(storageKeyToken)

        if(lToken !== null){
            let validity = getRemainingTime(lToken)
            if(validity > 180000){
                if(vefMe(lToken)){
                    vToken = lToken
                    setToken(lToken)
                    scheduleRefresh(lToken)
                }
            }                
        }
        
        return vToken
    }

    function savedMember()
    {
        let saved = localStorage.getItem(storageKeyMember)
        if(saved != null)
        {
            let sMember = JSON.parse(saved) as apiMember
            setLastEmail(sMember.email)
        }
    }

    function vefMe(lToken?: string): boolean
    {
        setWaiting(true)
        let vMe = localStorage.getItem(storageKeyMember)
        if(vMe !== null){
            lToken&& me(lToken)
            return true
        }

        return false
    }
    
    function login(email: string, password: string)
    {
        setWaiting(true)
        setLastEmail(email)
        api.post('auth/login',{
            email: email,
            password: password,
            space_id: spaceId
        }).then(
            (response) => { 
                let lToken = response.data.token
                setToken(lToken)
                me(lToken)
                setSuccessMessage("Login efetuado com sucesso!")
                localStorage.setItem(storageKeyToken, lToken)
                scheduleRefresh(lToken)
            }
        ).catch(
            error => {
                error.response !== undefined? updateError(error.response.status, error.response.data.error)
                : alert("Erro no servidor")

                setWaiting(false)
            }
        )
    }

    function logout(callback?: () => void)
    {
        localStorage.removeItem(storageKeyToken)
        const api = withToken(token, spaceId);
        api.get('auth/logout').finally(
            () => {
                setToken('')
                callback&& callback()
            }
        );
    }

    function me(token: string)
    {
        const api = withToken(token, spaceId);
        api.get('auth/me').then(
            (response) => {
                let lMember = response.data as apiMember
                setMember(lMember)
                loadTotalComments(lMember.level, token)
                localStorage.setItem(storageKeyMember, JSON.stringify(lMember))
            }
        ).catch(
            () => {
                setToken('')
            }
        )
    }

    function reloadMe(callback: (me: apiMember) => void)
    {
        const api = withToken(token, spaceId);
        api.get('auth/me').then(
            (response) => {
                let lMember = response.data as apiMember
                setMember(lMember)
                localStorage.setItem(storageKeyMember, JSON.stringify(lMember))
                callback(lMember)
                setLastEmail(lMember.email)
            }
        ).catch(
            error => {
                updateError(error.response.status, error.response.data.error)
            }
        );
    }

    function refreshToken(sToken: string)
    {
        const api = withToken(sToken, spaceId)
        api.get('auth/refresh').then(
            response => {
                let lToken = response.data.token
                setToken(lToken)
                setSuccessMessage("Token atualizado com sucesso.")
                localStorage.setItem(storageKeyToken, lToken)
                scheduleRefresh(lToken)
            }
        ).catch(
            error => {
                error.response !== undefined? updateError(error.response.status, error.response.data.error)
                : alert("Não foi possível renovar suas credenciais de acesso.")

                setToken('')
            }
        )
    }

    function deleteError()
    {
        setError({code: null, message: null})
    }

    function updateError(code: number, message: string)
    {
        let er = {
            code: code,
            message: message
        }
        setError(er)
    }

    function scheduleRefresh(sToken: string)
    {
        clearTimeout(timer.current)

        let time = getRemainingTime(sToken)
        time -= 180000

        if(time > 86400000)
            time = 86400000

        if(time > 0){
            timer.current = setTimeout(() => {
                refreshToken(sToken)
            }, time)
        }
    }

    return (
        <AuthContext.Provider 
            value={{ 
                token, 
                member, 
                spaceId, 
                error, 
                successMessage, 
                lastEmail,
                deleteError, 
                login, 
                logout, 
                reloadMe, 
                vefToken, 
                waiting,
                totalComments,
                setTotalComments }}
        >
            { children }
        </AuthContext.Provider>
    )
}

function useAuth(): iAuthContext {
    const context = useContext(AuthContext);
    return context;
}

export { AuthProvider, useAuth };