import React, { createContext, useContext, useState } from "react";
import FlashMessage from "../components/FlashMessage";
import { useTheme } from "./theme";
import Modal from "../components/Modal";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css'; 

interface iMessagesContext {
    showModalMessage(message: string, title?: string, error?: any): void;

    showFlashErrorMessage(message: string): void;

    showFlashSuccessMessage(message: string): void;
}

const MessagesContext = createContext<iMessagesContext>({} as iMessagesContext);

const MessagesProvider: React.FC = ({ children }) => {

    const { theme } = useTheme();

    const [message, setMessage] = useState("Teste de mensagem");
    const [flashVisible, setFlashVisible] = useState(false);
    const [flashBackgroundColor, setFashBackgroundColor] = useState(theme.colors.success);
    const [flashFontColor, setFlashFontColor] = useState(theme.colors.white);
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalText, setModalText] = useState('')
    const [modalError, setModalError] = useState<any>()

    function showModalMessage(nMessage: string, title?: string, error?: any)
    {
        title&& setModalTitle(title)
        setModalText(nMessage)
        error&& setModalError(error)
        setModalIsVisible(true)
    }

    function showFlashErrorMessage(nMessage: string)
    {
        setFlashFontColor(theme.colors.white)
        setFashBackgroundColor(theme.colors.warning)
        setMessage(nMessage)
        setFlashVisible(true)
        countDown()
    }

    function showFlashSuccessMessage(nMessage: string)
    {
        setFlashFontColor(theme.colors.white)
        setFashBackgroundColor(theme.colors.success)
        setMessage(nMessage)
        setFlashVisible(true)
        countDown()
    }

    function countDown()
    {
        setTimeout(() => {
            setFlashVisible(false)
        }, 2000)
    }

    function closeModal()
    {
        setModalIsVisible(false)
        setModalText('')
        setModalTitle('')
        setModalError(undefined)
    }

    return (
        <MessagesContext.Provider value={{showModalMessage, showFlashErrorMessage, showFlashSuccessMessage}}>
            <Modal 
                visible={modalIsVisible}
                onConfirm={closeModal}
                showDeleteButton={false}
                hideCancelButton
            >
                {modalTitle.length>0&& <h4>{modalTitle}</h4>}
                <p>{modalText}</p>
                {modalError&& <JSONPretty data={modalError}/>}
            </Modal>
            <FlashMessage backgroundColor={flashBackgroundColor} fontColor={flashFontColor} visible={flashVisible}>
                {message}
            </FlashMessage>
            { children }
        </MessagesContext.Provider>
    )
}

function useMessages(): iMessagesContext{
    const context = useContext(MessagesContext) 
    return context;
}

export { MessagesProvider, useMessages }


