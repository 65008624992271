import styled from 'styled-components'

interface iContainer {
    visible?: boolean;
}

export const Container = styled.div<iContainer>`
    display: ${props => props.visible? `block` : `none`};
    opacity: ${props => props.visible? 1 : 0};
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #000000aa;
    z-index: 3;
    padding-top: 3.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

export const ToolBar = styled.div`
    display: flex;
    justify-content: flex-end;
    color: ${props => props.theme.colors.white};
    font-size: 1.3rem;
`;

export const SearchField = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    
    padding: 5px;
    background-color: ${props => props.theme.colors.white};

    border: 1px solid ${props => props.theme.colors.surface_25};
    border-radius: 5px;

    margin-top: 16px;

    > input {
        flex: 1;
        padding: 0 6px;
    }

    > button {
        background-color: transparent;
        padding: 6px 12px;
        font-size: 1rem;
        color: ${props => props.theme.colors.surface_25};
    }
`;