import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { Container, Content, Links} from './style';

import resolveAvatarImage from "../../utils/resolveAvatarImage";

import { useAuth } from '../../contexts/auth';
import ProfileAvatar from "../ProfileAvatar";

const ProfileWidget: React.FC = () => {

    const { member, logout } = useAuth();
    const history = useHistory()

    function myAccount()
    {
        history.push('/profile')
    }

    function doLogout()
    {
        logout(
            () => {
                history.push("/")
            }
        )
    }

    return(
        <Container>
            <Content>
                <ProfileAvatar avatar={resolveAvatarImage(member)} size={45} action={myAccount}/>
                <Links>
                    <Link to="/profile">
                        Meu perfil
                    </Link>

                    <a href="#" onClick={doLogout}>
                        Sair
                    </a>
                </Links>
            </Content>
        </Container>
    )
}

export default ProfileWidget;