import styled from "styled-components";

interface iContainer {
    size: number;
}

export const Container = styled.div<iContainer>`
    width: ${props => props.size+'px'};
    height: ${props => props.size+'px'};

    position: relative;

    @media(max-width: 767px){
        position: static;
    }
`;

export const AvatarContainer = styled.button`
    padding: 2px;
    > img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 0 0 2px ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.white};
    }

    background-color: transparent;
`;