import styled from 'styled-components'

interface iContainer {
    visible?: boolean;
}

export const Container = styled.div<iContainer>`
    position: fixed;
    top: 0;
    left: ${props => props.visible? '0%' : '-60%'};
    width: 60%;
    height: 100vh;
    background-color: ${props => String(props.theme.colors.nav_1+"de")};
    background: ${props => `linear-gradient(180deg, ${props.theme.colors.nav_1} 0%, ${props.theme.colors.nav_2} 100%)`};
    box-shadow: ${props => props.visible? `1px 1px 16px ${props.theme.colors.gray_medium}` : `none`};
    margin-right: 60%;
    transition: left 0.2s;
    z-index: 4;
    padding-top: .5rem;
`;

export const Header = styled.div`
   color: ${props => props.theme.colors.white};
   font-size: 1.5rem;

   > .closeIcon{
       color: ${props => props.theme.colors.white};
   }

   padding: .8rem;
`;

export const Footer = styled.div`
    font-size: .9rem;
    text-align: center;
    color: ${props => props.theme.colors.white};
`;