import React from 'react';
import { useHistory } from 'react-router-dom';

import { useNav } from '../../contexts/nav';
import { MdClose } from 'react-icons/md';
import { useAuth } from '../../contexts/auth';

import { Container, Header, Footer } from './style';

interface iMobileSideMenu{
    visible?: boolean;
}

const MobileSideMenu: React.FC<iMobileSideMenu> = ( props ) => {

    const history = useHistory()
    const {setOpenedMenu, setCrumb} = useNav()
    const {logout} = useAuth()

  return (
    <Container visible={props.visible}>
        <Header>
            <span className="closeIcon" onClick={() => setOpenedMenu(false)}>
                <MdClose />
            </span>
        </Header>
        <div>
            {props.children}
        </div>
        <Footer>
            <span onClick={() => logout(() => {
                history.replace('')
                setOpenedMenu(false)
                setCrumb(0,'','')
            })}>Sair</span>
        </Footer>
    </Container>
  );
}

export default MobileSideMenu;