import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MdClose } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import { useNav } from '../../contexts/nav';

import { Container, ToolBar, SearchField } from './style';

interface iSearchModal {
    visible: boolean;
}

const SearchModal: React.FC<iSearchModal> = ( props ) => {

    const {setOpenedSearchBar} = useNav()
    const history = useHistory()

    const [word, setWord] = useState('')
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if(props.visible)
            inputRef.current?.focus()
    },[props.visible])

    function search(e: React.SyntheticEvent)
    {
        e.preventDefault()
        history.push('search/'+word)
        setWord('')
        setOpenedSearchBar(false)
    }

  return (
    <Container visible={props.visible}>
        <ToolBar>
            <span onClick={() => setOpenedSearchBar(false)}>
                <MdClose />
            </span>
        </ToolBar>
        <form onSubmit={search}>
            <SearchField>
                <input 
                    ref={inputRef} 
                    type="text" 
                    value={word} 
                    onChange={(e) => setWord(e.target.value)}
                    placeholder='Digite sua busca'
                />
                <button>
                    <FiSearch />
                </button>
            </SearchField>
        </form>
    </Container>
  );
}

export default SearchModal;