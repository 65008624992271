import styled from "styled-components";

interface iContainer {
    isOpen: boolean;
}

export const Container = styled.nav<iContainer>`
    height: 100%;
    display: flex;
    flex-direction: column;

    
    margin-top: 40px;

    @media(max-width: 988px){
        height: ${props => props.isOpen? 'auto':'0px'};
        margin-top: 0;
    }    
`;

export const MenuList = styled.ul`
    list-style: none;
    margin: 10px 1.5rem;
    color: ${props => props.theme.colors.white};
    flex: 1;

    border-top: 1px solid ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.white};

    padding: 2rem 1rem;

    @media(max-width: 988px){
       padding: 1rem 0;
       margin-bottom: 3rem;
       flex: none;
    } 
`;

export const Title = styled.div`
    font-size: .7rem;
    color: ${props => props.theme.colors.white};
    margin-left: 2rem;

    @media(max-width: 988px){
        margin-top: 25px;
    } 
`;