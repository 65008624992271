import React from "react";
import { Route, Switch } from "react-router-dom";

import Layout from "../components/Layout";
import LoadingContainer from "../components/LoadingContainer";

const PageSpace = React.lazy(() => import("../pages/Space"));
const PageFolder = React.lazy(() => import("../pages/Folder"));
const PageCourse = React.lazy(() => import("../pages/Course"));
const PageAccount = React.lazy(() => import("../pages/Account"));
const CourseForm = React.lazy(() => import("../pages/CourseForm"));
const LessonForm = React.lazy(() => import("../pages/LessonForm"));
const PageTheme = React.lazy(() => import("../pages/Theme"));
const PageMembers = React.lazy(() => import("../pages/Members"));
const PageCommunity = React.lazy(() => import("../pages/Community"));
const PageProfile = React.lazy(() => import("../pages/Profile"));
const PageSupport = React.lazy(() => import("../pages/Support"));
const PageRanking = React.lazy(() => import("../pages/Ranking"));
const MemberForm = React.lazy(() => import("../pages/MemberForm"));
const PageSearch = React.lazy(() => import("../pages/Search"));
const PageConfigs = React.lazy(() => import("../pages/Configs"));
const PageIntegrations = React.lazy(() => import("../pages/Integrations"));

const AppRoutes: React.FC = () => {

    return (
        <Layout>
            <React.Suspense fallback={<LoadingContainer />}>
                <Switch>
                    <Route path="/" exact component={ PageSpace }/>
                    <Route path="/account" exact component={ PageAccount }/>
                    <Route path="/profile" exact component={ PageProfile }/>
                    <Route path="/profile/:member_id?" exact component={ PageProfile }/>
                    <Route path="/theme" exact component={ PageTheme }/>
                    <Route path="/members" exact component={ PageMembers }/>
                    <Route path="/members/form/:member_id?" exact component={ MemberForm }/>
                    <Route path="/community" exact component={ PageCommunity }/>
                    <Route path="/support" exact component={ PageSupport }/>
                    <Route path="/ranking" exact component={ PageRanking }/>
                    <Route path="/configs" exact component={ PageConfigs }/>
                    <Route path="/integrations" exact component={ PageIntegrations }/>
                    <Route path="/search/:word" exact component={ PageSearch } />
                    <Route path="/course/:folder_id/:course_id?" exact component={ CourseForm }/>
                    <Route path="/lesson/:course_id/:lesson_id?" exact component={ LessonForm }/>
                    <Route path="/:folder_id" exact component={ PageFolder }/>
                    <Route path="/:folder_id/:course_id" exact component={ PageCourse }/>
                    <Route path="/:folder_id/:course_id/:lesson_id" exact component={ PageCourse }/>
                </Switch>
            </React.Suspense>
        </Layout>
    )
}

export default AppRoutes;