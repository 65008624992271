import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    html, body, #root{
        height: 100%;
        font-family: Cerebri, serif;

        /*-webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;*/
    }

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    *, button, input{
        border: 0;
        outline: 0;
        letter-spacing: .02rem;
        font-family: Cerebri, serif;
    }

    button{
        cursor: pointer;
    }    
`; 