import React from 'react';

import { Container, Message } from './style';

interface iFlashMessage {
    backgroundColor: string;
    fontColor: string;
    visible: boolean;
}

const FlashMessage: React.FC<iFlashMessage> = ( {children, visible, ...rest} ) => {
  return (
    <Container visible={visible}>
        <Message {...rest}>
            <div>{ children }</div>
        </Message>
    </Container>
  );
}

export default FlashMessage;