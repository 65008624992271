import React from 'react';

import { Container } from './style';

interface iBuutonLarge {
    onClick?(): void;
    width?: string;
}

const ButtonLarge: React.FC<iBuutonLarge> = ( props ) => {
  return (
    <Container onClick={props.onClick} style={{ width: props.width? props.width : 'auto'}}>
        { props.children }
    </Container>
  );
}

export default ButtonLarge;