import React from "react";

import { Container, AvatarContainer } from './style';

interface iProfileAvatar {
    avatar: string;
    size: number;
    action?(): void;
}

const ProfileAvatar: React.FC<iProfileAvatar> = ( props ) => {    
    return (
        <Container size={props.size}>
            <AvatarContainer onClick={() => {props.action&& props.action()}}>
                <img src={props.avatar} alt="Avatar"/>
            </AvatarContainer>
        </Container>
    )
}

export default ProfileAvatar;