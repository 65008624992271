import styled from 'styled-components'

interface iContainer {
  hideTopLine?: boolean;
  topMargin?: string;
  topPadding?: string;
}

export const Container = styled.div<iContainer>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ${props => props.topPadding? props.topPadding : '20px'};
    margin-top: ${props => props.topMargin? props.topMargin : '40px'};
    border-top: ${props => props.hideTopLine? 'none' : '1px solid '+props.theme.colors.gray_light};

    > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > :only-child {
        justify-content: flex-end;
    }

    > :first-child {
        justify-content: flex-start;
    }

    > :last-child {
        justify-content: flex-end;
    }

    @media(max-width: 768px){
        flex-direction: column;

        > div {
           width: 100%;
           align-items: center;
        }

        > :first-child {
            justify-content: flex-start;
        }

        > :last-child {
            justify-content: flex-end;
        }

        > :only-child {
            justify-content: flex-end;
        }
    }
`;