import React from "react";

import { Grid } from "./style";
import { useNav } from "../../contexts/nav";
import { useAuth } from "../../contexts/auth";
import SideBar from "../SideBar";
import Content from "../Content";
import MobileSideMenu from "../MobileSideMenu";
import SideMenu from "../SideMenu";
import SearchModal from "../SearchModal";

const Layout: React.FC = ( props ) => {

    const {totalComments} = useAuth()
    const {openedMenu, setOpenedMenu, openedSearchBar, spaceFolders} = useNav()

    function handleSelect(){
        setOpenedMenu(false)
    }

    return (
        <Grid>
            <SideBar />
            <Content>
                { props.children }
            </Content>
            <MobileSideMenu visible={openedMenu}>
                <SideMenu
                    isOpen={true}
                    onSelect={() => handleSelect()}
                    commentsBadge={totalComments.pending}
                    folders={spaceFolders}
                />
            </MobileSideMenu> 
            <SearchModal visible={openedSearchBar}/>            
        </Grid>
    )
}

export default Layout;