import styled from "styled-components";

interface iButton {
    fontSize?: string;
    fontColor?: string;
    fontWeight?: string;
    backgroundColor?: string;
    borderColor?: string;
    borderWeight?: number;
    focussedBorderColor?: string;
    mouseHoverBackgroundColor?: string;
    width?: string;
    padding?: string;
    margin?: string;
    textAlign?: string;
}

export const Container = styled.button<iButton>`
    width: ${props => props.width? props.width : 'auto'};
    padding: ${props => props.padding? props.padding : '7px .75rem 7px .75rem'};
    font-size: ${props => props.fontSize? props.fontSize : 'inherit'};
    font-weight: ${props => props.fontWeight? props.fontWeight : '400'};
    color: ${props => props.fontColor? props.fontColor : props.theme.colors.gray_dark};
    background-color: ${props => props.backgroundColor? props.backgroundColor : props.theme.colors.tertiary};
    border: ${props => props.borderWeight? props.borderWeight+'px' : '1px'} solid ${props => props.borderColor? props.borderColor : props.theme.colors.gray_light};

    line-height: ${props => props.fontSize? props.fontSize : '1.3rem'};
    text-align: ${props => props.textAlign? props.textAlign : 'left'};
    
    border-radius: 5px;
    margin: ${props => props.margin? props.margin : '0 .3rem 0 .3rem'};

    display: flex;
    align-items: center;
    justify-content: ${props => props.textAlign? props.textAlign : 'flex-start'};;

    :focus{
        
        box-shadow:
            0 0 0 2px ${props => props.theme.colors.white},
            0 0 0 4px ${props => props.focussedBorderColor? props.focussedBorderColor : props.theme.colors.primary};
        background-color: ${props => props.backgroundColor? props.backgroundColor : props.theme.colors.tertiary};
    }

    :hover {
        filter: brightness(95%);
        -webkit-filter: brightness(95%);
        -moz-filter: brightness(95%);
    }

    :disabled {
        color: ${props => props.theme.colors.gray_light};
    }

    transition: .2s;
`;